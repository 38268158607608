<template>
  <div class="znzz">
    <div class="header">
      <div class="zhuti">
        <div class="h1">企业数字化</div>
        <div class="h2">
          <p>企业数字化转型方案设计，挖掘企业经营痛点，集成</p>
          <p>跨地区、跨系统企业数据，精细化企业运营管理</p>
        </div>
      </div>
    </div>
    <div class="titles">应用场景</div>
    <div class="cj">
        <div class="cjlist " v-for="(li,index) in yycjList" :class="`cjlist${index}`" :key="index">
          <div class="cjpo flex" @click= changePath(li)>
            <div class="cjimage"><el-image :src="li.image" v-if="li.image"></el-image></div>
            <div >
              <div class="name">{{li.title}}</div>
              <div class="desc">{{li.titleSub}}</div>
            </div>
          </div>
        </div>
    </div>
    <div class="titles">企业数字化蓝图</div>
    <div>
      <el-image :src="datas.lantu.image" v-if="datas.lantu.image"></el-image>
    </div>
    <div class="titles">企业数字化方案</div>
    <div class="bg1">
      <div class="zhuti">
        <div class="flex">
          <div class="hdimage display" ref="hdimage" @mouseenter="remove" :class="{'remove-class':index==0}"  v-for="(li,index) in fanganList" :key="index">
            <el-image :src="li.image" fit="cover" v-if="li.image"></el-image>
            <div class="hangye">
              <div class="hangyetitle">{{li.title}}</div>
              <div>{{li.titleSub}}</div>
            </div>
            <div class="htitle">{{li.title}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="titles" style="margin-bottom: 0">企业数字化行业资讯</div>
    <div class="bg">
      <div class="zhuti allfangan">
        <div class="flex fangan" v-for="(li,index) in hyzxList" :key="index">
          <div class="image"><el-image :src="li.accessorySet"></el-image></div>
          <div style="width: 300px;color: #999">
            <div class="title ellipsis2">{{li.title}}</div>
            <div class="ellipsis3" style="height: 57px;">{{li.text | filterHtmlTag}}</div>
            <div class="time">{{li.createTime}}</div>
          </div>
        </div>
      </div> 
    </div>
    <div class="titles" style="margin-bottom: 0">需求咨询留言</div>
    <div class="advice">
        <Advice></Advice>
    </div>
    <div class="zhuti">
      <div class="titles">第三方推荐</div>
      <MoreList :moreList="moreList"></MoreList>
    </div>
  </div>
</template>

<script>
import {enterprisedigitizationindex} from "../../request/moudle/znzz";
import Advice from "../common/advice.vue";
import MoreList from "../common/moreList.vue";

export default {
  name: "",
  components:{
    Advice,
    MoreList
  },
  data(){
    return  {
      datas:'',
      fanganList:[],
      yycjList:[],
      hyzxList:[],
      moreList:[],
      imgUrl: '',
      imgList: [],
    }
  },
  created() {
    this.loadconfig()
  },
  mounted() {
  },

 filters: {
    filterHtmlTag(t) {
      if (!t) return '';
      return t.toString().replace(/<.*?>/g, '');
    },
  },
  methods:{
    loadconfig(){
      this.$api.znzz.enterprisedigitizationindex().then((res)=>{
        this.datas=res.data
        this.fanganList=res.data.fanganList
        this.yycjList=res.data.yycjList
        this.hyzxList=res.data.hyzxList
        this.moreList=res.data.moreList
      })
    },
    remove(e){
      let hdimage=this.$refs.hdimage
      for (const val of hdimage){
        val.className='hdimage display'
      }
      e.target.className='hdimage remove-class show'
    },
    
    //页面跳转
    changePath (item){
      console.log(item.title)
      if(item.title == "私域商城"){
         this.$router.push("/sysc")
      }else if(item.title == "仓储物流"){
         this.$router.push("/wuliu")
      }else if(item.title == "生产管理") {
         this.$router.push("/shengchan")
      }else if(item.title == "设备管理") {
         this.$router.push("/shebei")
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.znzz{
  background: #fff;
}
.header{
  height: 230px;
  background: url("../../assets/banner/compnumber.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  color: #fff;
  .h1{
    font-size: 42px;
    margin-top: 25px;
  }
  .h2{
    font-size: 20px;
    line-height: 30px;
    margin-top: 15px;
  }
}
.bg{
  background: linear-gradient(#FEFEFF,#F0F5FE);
  padding: 56px 0;
}
.allfangan{
  overflow: hidden;
  background: #fff;
  height: 380px;
  .fangan{
    float: left;
    margin: 16px;
    .image{
      width: 210px;
      height: 150px;
      margin-right: 32px;
    }
    .title{
      color: #1D1D1D;
      font-size: 16px;
      margin-bottom: 15px;
      height: 42px;
    }
    .time{
      margin-top: 15px;
    }
  }
}

.bg1{
  background: url("../../assets/znzz/bg1.png") no-repeat;
  background-size: 100% 100%;
  height: 500px;
  .hdimage{
    width: 200px;
    height: 500px;
    transition: all 0.2s ease-in-out;
    position: relative;
  }
  .display{
    .htitle{
      width: 100%;
      text-align: center;
      position: absolute;
      font-size: 24px;
      bottom: 25px;
      color: #fff;
    }
    .hangye{
      display: none;
    }
  }
  .show{
    .htitle{
      display: none;
    }
    .hangye{
      position: absolute;
      left: 0;
      color: #fff;
      top: 0;
      bottom: 0;
      padding: 0 56px;
      background: rgba(38,48,84,0.8);
      line-height: 25px;
    }
    .hangyetitle{
      font-size: 24px;
      margin-bottom: 25px;
      margin-top: 125px;
    }
  }
  .remove-class{
    width: 400px;
  }
}

.cj{
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
  .cjpo{
    width: 600px;
    position: absolute;
    top: 30px;
    cursor: pointer;
  }
  .cjlist:nth-child(odd) .cjpo{
    left: 0;
  }
  .cjlist{
    width: 550px;
    height: 120px;
    float: left;
    position: relative;
    margin: 15px;
  }
  .cjlist0{
    background: url("../../assets/znzz/cjlist0.png") no-repeat;
    background-size: 100% 100%;
  }
  .cjlist1{
    background: url("../../assets/znzz/cjlist1.png") no-repeat;
    background-size: 100% 100%;
  }
  .cjlist2{
    background: url("../../assets/znzz/cjlist2.png") no-repeat;
    background-size: 100% 100%;
  }
  .cjlist3{
    background: url("../../assets/znzz/cjlist3.png") no-repeat;
    background-size: 100% 100%;
  }
  .cjimage{
    width: 40px;
    height: 40px;
    margin: 15px 25px;
  }
  .name{
    font-size: 16px;
    color: #1D1D1D;
  }
  .desc{
    width: 410px;
    color: #999;
    font-size: 12px;
    margin-top: 5px;
  }
}
.advice {
   width: 1120px;
   height: 80px;
   padding: 50px 40px;
   margin: 60px auto 30px auto;
   border-radius: 6;
   background: #fff;
   box-shadow: 1px 1px 5px rgba(0,0,0,.1);
}
.zhuti{
  width: 1200px;
  margin: 0px auto;
}
.titles{
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  margin: 56px;
  color: #1D1D1D;
}
.smooth-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  .swiper-container {
    width: 100%;
    height: 380px;
    overflow: hidden;
    background-color: #fff !important;
    .swiper-wrapper {
      transition-timing-function: linear !important; //想好具体位置放到哪，得能替换！
    }
 
    .swiper-item { 
      .img {
        width: 100%;
        height: 300px;
        cursor: pointer;
      }
    }
  }
 
  .swiper-button-prev1,
  .swiper-button-next1 {
 
    width: 30px;
    position: absolute;
    top: 0;
    bottom: 24px;
    z-index: 11111;
    display: flex;
    align-items: center;
    font-size: 30px;
    cursor: pointer;
  }
 
  .swiper-button-prev1 {
    left: 0px;
  }
 
  .swiper-button-next1 {
    right: 0;
  }
}
</style>